<template>
    <v-row class="px-5">
        <v-container>
            <div style="height: 20px;"></div>
            <v-row justify="center"  class="py-5">
                <v-col style="text-align: center">
                    <h1 :class="[$vuetify.breakpoint.mdAndUp ? 'text-h3' : 'text-h4', 'font-weight-light']">Forget passwords.</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="12" md="10" lg="8" xl="6" class="pa-0"  style="text-align: center">
                <!-- the 4-point statement -->
                <!-- <h1 class="text-h6 font-weight-light my-2">Protect online accounts from hackers using a sophisticated one-tap login system,<br/>and do business with confidence.</h1> -->
                    <p class="my-2"><span :class="[$vuetify.breakpoint.mdAndUp ? 'text-h4' : 'text-h5', 'font-weight-light']" style="line-height: 1.5">We provide a sophisticated one-tap login system so you can protect online accounts from hackers.</span></p>
                </v-col>
            </v-row>
            <div style="height: 20px;"></div>
        </v-container>
    </v-row>
</template>

<style scoped>

</style>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            session: (state) => state.session,
        }),
        ...mapGetters({
            currentAccount: 'account', // currently selected account object
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
    },
    methods: {
        init() {
            if (this.isReady && this.isAuthenticated && this.currentAccount) {
                /*
                // see also navbar.vue
                if (this.currentAccount.type === 'Free') {
                    this.$router.push('/dashboard/free');
                }
                if (this.currentAccount.type === 'Personal') {
                    this.$router.push('/dashboard/personal');
                }
                if (this.currentAccount.type === 'Enterprise') {
                    this.$router.push('/dashboard/enterprise');
                }
                */
                this.$router.push('/dashboard');
            }
        },
    },
    watch: {
        isReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    mounted() {
        this.init();
    },
};
</script>
